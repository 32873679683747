import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useTransportsList() {
    const transprtListTable = ref([]);

    const transportTableColumns = [
        { text: i18n.t('Name'), value: 'name', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Type'), value: 'type', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Capacity'), value: 'capacity', class: 'text-uppercase', width: '12rem' },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const totalTransportListTable = transprtListTable.length;
    const oldSearch = ref('');
    const oldCapacityFilter = ref([0, 100]);
    const oldTypeFilter = ref('');

    const fetchTransportsList = () => {
        let verifyQuery = store.getters['app-transport/getTransportsQuery'];
        console.log(verifyQuery);
        console.log(oldCapacityFilter.value[0]);

        store
            .dispatch('app-transport/fetchTransportList')
            .then((response) => {
                console.log(response);

                if (
                    oldSearch.value == verifyQuery.filters.$or[0].name.$containsi &&
                    oldCapacityFilter.value[0] == verifyQuery.filters.capacity.$between[0] &&
                    oldCapacityFilter.value[1] == verifyQuery.filters.capacity.$between[1] &&
                    oldTypeFilter.value == verifyQuery.filters.type.$containsi
                ) {
                    transprtListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].name.$containsi;
                    oldCapacityFilter.value = verifyQuery.filters.capacity.$between;
                    oldTypeFilter.value = verifyQuery.filters.type.$containsi;
                    transprtListTable.value = [];
                    transprtListTable.value.push(...response.data.data);
                }

                totalTransportListTable.value = response.data.meta.pagination.total;
                // parkTotalLocal.value = parkTotal;1

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return {
        transportTableColumns,
        transprtListTable,
        totalTransportListTable,
        fetchTransportsList,
    };
}
